.footer-logo img {
    width: 90%;
}
.Footer {
    padding: 100px 80px 0px 80px;
}
.Footer ul {
    padding: 0px;
}

.Footer ul li {
    list-style-type: none;
    line-height: 45px;
}
.Footer ul li a {
    text-decoration: none;
    color: #6b74ad;
    font-size: 17px;
}
.footer-contact li {
    color: #8f8f8f;
    font-size: 24px;
}
.condition ul {
    display: flex;
    gap: 18px;
    justify-content: flex-end;
    
}
.row.copyright\&condition {
    margin-top: 50px;
    color: #6a7395;
    font-weight: 600;
}
.social-links a {
    font-size: 18px;
    display: inline-block;
    background: #9d9da8;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    margin-right: 14px;
}
.social-links {
    margin-top: 20px;
}
.social-links a:hover {
    background-color: #ffc451;
}
.Footer-links ul li:hover {
    cursor: pointer;
  }
  
  .Footer-links ul li a:hover {
    color:#ffc451;
  }
  .Footer-links ul li:nth:hover {
    color:#ffc451;
    }

  @media only screen and (max-width: 1440px) {
    .condition ul {
        position: relative;
        bottom:0.4rem;
    }
  }


@media only screen and (max-width: 1440px) {
   
    .Footer {
        padding: 100px 80px 0px 80px;
    }
    .Footer {
        padding: 100px 80px 0px 80px;
    }

}

@media only screen and (max-width: 1024px) {

    .Footer {
        padding: 100px 10px 0px 10px;
    }
}
@media only screen and (max-width: 768px) {
    .Footer {
        padding: 25px 31px 0px 39px;
    }
    .social-links {
        margin: 15px 0px 50px 0px;
    }
    .footer-logo {
        text-align: center;
    }
    .row.copyright\&condition {
        display: flex;
        align-items: center;
    }
    .footer-logo img {
        width: 50%;
    }
    .Footer-links.footer-empty{
        display: none;
    }
    .Footer.row {
        display: flex;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 450px) {
    .Footer ul li a {
        display: flex;
        margin-left:1px;
    }
    .row.copyright\&condition {
        display: flex;
        flex-direction: column-reverse;
        line-height: 0.1px;
    }
    .condition ul{
        display: flex;
        justify-content: flex-start;
        
    }
}
@media only screen and (max-width: 768px) {
    .condition ul {
        padding-top: 1rem;
    }

}
@media only screen and (max-width: 425px) {
    .footer-logo {
        text-align: left;
        padding-left: 9px;
        
    }
    .social-links {
        position: relative;
        right: 4px;
    }
    .Footer {
        padding: 0px 0px 0px 6px;
        
    }
    .condition ul {
        justify-content: flex-start;
        padding-left: 12px;
        gap: 6px;
        
    }
    .Footer ul li a {
        font-size: 18px;
    }
    .footer-contact li {
        font-size: 18px;
    }
    .footer-contact li a {
        line-height: 40px;
    }
    .row.copyright\&condition {
        margin-top: 10px;
        
    }
    .footer-contact h2{
        padding-left:0.3rem;
    }   
    .footer-contact ul{
        padding-left:0.3rem;
        line-height: 2.5rem;
    }
    .social-links {
        padding-left: 5px;
        
    }
    .Footer-links {
        padding-left: 17px;
    }
    .section #contact {
        padding: 70px 15px;
    }
    .footer-logo img {
        width: 50%;
        margin-left: -18px;
        margin-bottom: 10px;
    }
    .Footer-links {
        display: flex;
    }
    .Footer-links ul {
        padding-top: 2rem;
        position: relative;
        right: 3.4rem;
    }
    .social-links {
        margin: 5px 0px 30px 0px;
    }
    .copyright p {
        margin-bottom: 0px;
    }
    .copyright {
        padding-left: 25px;
    }
    .Footer ul li a {
        font-size: 16px;
    }
}
