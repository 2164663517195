form.php-email-form .form-control::placeholder {
  color: grey;
  font-family: "Outfit", sans-serif;
  font-weight: 300;
  font-size: 18px;
}
.contact .info-box {
    cursor: pointer;  
    color: #444444;
    background: #fafbff;
    padding: 30px;
  }
  
  .contact .info-box i {
    font-size: 38px;
    line-height: 0;
    color: #4154f1;
  }
  
  .contact .info-box h3 {
    font-size: 20px;
    color: #012970;
    font-weight: 700;
    margin: 20px 0 10px 0;
  }
  
  .contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .contact .php-email-form {
    background: #fafbff;
    padding: 30px;
    height: 100%;
    
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    animation: animate-loading 1s linear infinite;
  }
  
  .contact .php-email-form input,
  .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    border-radius: 2;
    
  }
  
  .contact .php-email-form input:focus,
  .contact .php-email-form textarea:focus {
    border-color: #4154f1;
    font-weight: 700;
    
  }
  
  .contact .php-email-form input[type=text],
  .contact .php-email-form input[type=email] {
    
    
  }
  
  .contact .php-email-form textarea {
    padding: 12px 15px;
  }
  
  .contact .php-email-form button[type=submit] {
    background: #262262;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    font-family: "Outfit", sans-serif;  
    transition: 0.4s;
    border-radius: 5px;
  }
  
  .contact .php-email-form button[type=submit]:hover {
    background: #423BAB;
  }

  .form-control.focused {
    padding-top: 20px; /* Adjust according to your design */
  }
  
  .form-control.focused::placeholder {
    transform: translateY(-100%);
    font-size: 12px; /* Adjust the font size as needed */
  }
  
  .section-header h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0;
    color: #4154f1;
    text-transform: uppercase;
}
.section-header p {
    margin: 10px 0 0 0;
    padding: 0;
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #012970;
}

@media only screen and (max-width: 320px) {
  header.section-header {
    margin-bottom: 2.5rem;
}
}

header.section-header {
    margin-bottom: 2rem;
}
section#contact {
    text-align: center;
    padding: 100px 0px;
}
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width:425px) {

    section#contact {
      padding: 100px 15px;
  } 
  }

  .name-field {
    align-items: flex-start;
  }
  
  
  @media  screen and (max-width: 2440px) {
    .mobile-field {
      width: 100%;
      
    }
    .message-field {
      width: 100%;
    }
    } 