
.about-section3 .container {
    background-color: transparent;
    background-image: linear-gradient(75deg,#2b5cfd 31%,#1d3faf 76%);
    padding: 60px 0 60px 50px;
    border-radius: 50px;
}
.abt-sec3-content h2 {
    font-size: 3.75rem;
    line-height: 70px;
    color: #fff;
    
}
.abt-sec3-img img {
    width: 95%;
    margin-right: -1px;
}
.abt-sec3-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.abt-sec3-img {
    text-align: end;
}
a.abt-btn1 {
    background: #fff;
    color: #2b5cfd;
    padding: 11px 63px 11px 63px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
}
a.abt-btn2 {
    color: #ffffff;
    padding: 11px 63px 11px 63px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #fff;
}
.abt-section3-btn {
    display: inline-flex;
    align-items: center;
    gap: 1.7rem;
    margin-top: 30px;
}
.features-sec1-content h2 {
    font-size: 30px;
}
.features-sec1-content span small {
    font-size: 35px;
    margin: 0px 30px;
}
.features-service-item {
    width: 45%;
}
.features-service-item h3 {
    font-size: 20px;
    font-weight: 700;
}
.features-service-item img{
    height: 1.8rem;
    width: 1.8rem;
}
