.fearures-title {
    text-align: center;
    padding-bottom: 80px;
}
.fearures-title h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}
.features-sec1-content h2 {
    color: #000000;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.4em;
}
.features-sec1-content span {
    position: relative;
    display: inline-block;
}
.features-sec1-content span:before {
    background-image: url("../../../../public/Assests/shap_style_6.png");
    position: absolute;
    content: "";
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    width: 100%;
    height: 100%;
}
.features-sec1-content span small {
    position: relative;
    color: #fff;
    font-size: 45px;
    margin: 0px 48px;
}
.features-sec1-content p {
    color: #212529;
    font-size: 16px;
    line-height: 2em;
    text-align: justify;
}
.features-section1 {
    padding: 80px 0px;
}
.features-sec1-img img {
    width: 90%;
}
.features-sec1-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.features-service-item {
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    border-radius: 15px 15px 15px 15px;
    padding: 25px 25px 25px 25px;
    box-shadow: -4.104px 11.276px 38px 0px rgba(45.000000000000014, 42, 61, 0.10196078431372549);
    width: 46%;
    margin: 10px;
    transition: .3s ease-in-out;
}
.features-service-item:hover {
    transform: translateY(-10px);
}
.features-service-item h3 {
    font-size: 18px;
    margin-top: 10px;
}
.features-sec1-img.col-lg-6 {
    display: flex;
    align-items: center;
}

@media (max-width: 1024px) {
    .features-service-item {
        width: 45%;
    }
    .features-service-item h3 {
        font-size: 17px;
        margin-top: 10px;
    }
    .features-sec1-content h2 {
        font-size: 30px;
    }
    .features-sec1-content span small {
        font-size: 30px;
        margin: 0px 26px;
    }
}
@media (max-width:768px) {

    .features-section1 .row {
        display: flex;
        flex-direction: column-reverse;
    }
    .feartures-service.row {
        display: flex;
        flex-direction: row;
    }
    .feartures-service title {
        font-size: 24px;
        font-weight: 700;
    }
    .features-sec1-img img {
        width: 70%;
    }
    .features-sec1-img.col-lg-6 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
    }
}
@media (max-width: 520px) {
   
    .feartures-service.row {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .features-sec1-content {
        padding: 30px;
    }
    .features-service-item {
        width: 90% !important;
    }
    .features-sec1-content span small {
        font-size: 24px;
        margin: 0px 26px;
    }
    .fearures-title {
        padding-bottom: 30px;
    }
}

