section#pricing .row {
  justify-content: center;
  }

.pricing .box {
    padding: 20px;
    background: #fff;
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .pricing h3 {
    font-weight: 400;
    margin: -20px -20px 20px -20px;
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #777777;
    background: #e7f1ff;
  }
  
  .pricing h4 {
    font-size: 36px;
    color: #ff7f5d;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }

  .pricing h4 sup {
    font-size: 20px;
    top: -15px;
    left: -3px;
  }
  
  .pricing h4 span {
    color: #bababa;
    font-size: 16px;
    font-weight: 300;
  }
  
  .pricing ul {
    padding: 0;
    list-style: none;
    color: #444444;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
  }
  
  .pricing ul li {
    padding-bottom: 16px;
  }
  
  .pricing ul i {
    color: #ff7f5d;
    font-size: 18px;
    padding-right: 4px;
  }
  
  .pricing ul .na {
    color: #ccc;
    text-decoration: line-through;
  }
  
  .pricing .btn-wrap {
    margin: 20px -20px -20px -20px;
    padding: 20px 15px;
    background: #e7f1ff;
    text-align: center;
  }
  
  .pricing .btn-buy {
    background: #25377d;
    display: inline-block;
    padding: 8px 35px 9px 35px;
    border-radius: 4px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    transition: 0.3s;
    text-decoration: none;
  }
  
  .pricing .btn-buy:hover {
    background: #ff9377;
  }
  
  .pricing .featured h3 {
    color: #fff;
    background: #25377d;
  }
  
  .pricing .advanced {
    width: 200px;
    position: absolute;
    top: 18px;
    right: -68px;
    transform: rotate(45deg);
    z-index: 1;
    font-size: 14px;
    padding: 1px 0 3px 0;
    background: #ff7f5d;
    color: #fff;
  }
  .section-title {
    text-align: center;
    position: relative;
    bottom: 3rem;
}
.section-title h2 {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
    
}
.section-title p {
    margin: 0px 160px;
}
section#pricing {
    padding: 100px 0px;
}
@media (max-width:1024px) {
  .section-title p {
    margin:0px;
}
}
@media (max-width:425px) {
  .section-title p {
    text-align: justify;
}
section#pricing {
  padding: 60px 30px;
}

}
