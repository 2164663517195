.nav-logo img {
    width: 80%;
}
.navbar-container {
    padding: 0px 100px;
}
.navbar-container .row {
    display: flex;
    align-items: center;
}

.navbar a {
    font-size: 26px;
    color: #2c2c2c;
    text-decoration: none;
    font-weight: 500;
    transition: .3s ease;
}
.navbar {
    justify-content: end;
    gap: 60px;
}
.navbar :hover {
    color: #fcb040;
}
.navbar-container button {
    display: none;
}

@media only screen and (max-width: 1440px) {
    .navbar a {
        font-size: 24px;
    }
}
@media only screen and (max-width: 1024px) {
    .navbar a {
        font-size: 18px;
    }
    .navbar-container {
        padding: 0px 40px;
    }
    .navbar {
        gap: 50px;
    }
    .navbar a {
        font-size: 20px;
    }
}
@media only screen and (max-width: 768px) {
    .nav-logo img {
        width: 90%;
    }
    .navbar a {
        font-size: 18px;
    }
    .navbar {
        gap: 35px;   
    }
    .navbar-container {
        padding: 0px 26px;
   
    }
}
@media only screen and (max-width: 450px) {
    
    .navbar-container button {
        padding-top: 1rem;
    }
    .header-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .navbar-container button > * {
        width: 100%;
        height: 40px;
        margin-top: 2.7px;
        
    }
}
@media only screen and (max-width: 320px) {
    .navbar-container button > * {
        position: relative;
        bottom: 0.45rem;
    }
}


@media only screen and (max-width: 520px) {
    
    .moble-menu a {
        font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    }
    .navbar-container button {
        display: block  ;
    }
    .navbar {
        display: none;
    }
    .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
        width: 85%; 
    }
    .moble-menu {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 18px;
    }
    .moble-menu a {
        text-decoration: none;
        color: #000;
    }
    .moble-menu a:hover {
        color: #fcb040;
    }
    
.navbar-container button {
    position: absolute;
    top: 12px;
    right: 37px;
}

.nav-logo img {
    width: 60%;
}
.navbar-container button {
    padding-left: 35px;

}
.navbar-container button {
    display: block;

}
.moble-menu {
    padding-left: 20px;
}
img.nav-logo2 {
    width: 4%;
    margin-left: -23px;
}
}

@media only screen and (max-width: 375px) {

    .navbar-container button {
        position: absolute;
        top: 12px;
        right: 37px;
    }
}