.panel {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #fff;
    background: none;
    box-shadow: none;
  }
  
  .panel:last-child {
    border-bottom: none;
  }
  
  .panel-group > .panel:first-child .panel-heading {
    border-radius: 4px 4px 0 0;
  }
  
  .panel-group .panel {
    border-radius: 0;
  }
  
  .panel-group .panel + .panel {
    margin-top: 0;
  }
  
  .panel-heading {
    background-color: #009688;
    border-radius: 0;
    border: none;
    color: #fff;
    padding: 0;
  }
  
  .panel-title a {
    display: block;
    color: #fff;
    padding: 15px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
  }
  
  .panel-body {
    background: #fff;
  }
  
  .panel:last-child .panel-body {
    border-radius: 0 0 4px 4px;
  }
  
  .panel:last-child .panel-heading {
    border-radius: 0 0 4px 4px;
    transition: border-radius 0.3s linear 0.2s;
  }
  
  .panel:last-child .panel-heading.active {
    border-radius: 0;
    transition: border-radius linear 0s;
  }
  /* #bs-collapse icon scale option */
  
  .panel-heading a:before {
    content: '\e146';
    position: absolute;
    font-family: 'Material Icons';
    right: 5px;
    top: 10px;
    font-size: 24px;
    transition: all 0.5s;
    transform: scale(1);
  }
  
  .panel-heading.active a:before {
    content: ' ';
    transition: all 0.5s;
    transform: scale(0);
  }
  
  #bs-collapse .panel-heading a:after {
    content: ' ';
    font-size: 24px;
    position: absolute;
    font-family: 'Material Icons';
    right: 5px;
    top: 10px;
    transform: scale(0);
    transition: all 0.5s;
  }
  
  #bs-collapse .panel-heading.active a:after {
    content: '\e909';
    transform: scale(1);
    transition: all 0.5s;
  }
  /* #accordion rotate icon option */
  
  #accordion .panel-heading a:before {
    content: '\e316';
    font-size: 24px;
    position: absolute;
    font-family: 'Material Icons';
    right: 5px;
    top: 10px;
    transform: rotate(180deg);
    transition: all 0.5s;
  }
  
  #accordion .panel-heading.active a:before {
    transform: rotate(0deg);
    transition: all 0.5s;
  }
  .faq-section1 {
    padding: 100px 0px;
}
.accordion h3 {
    font-size: 50px;
    color: #25377d !important;
    font-weight: 800;
    margin-bottom: 30px;
}
button.accordion-button {
    font-size: 22px;
    color: #25377d;
    font-weight: 700;
}
.faq-img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.faq-img img {
    width: 60%;
}

@media (max-width:768px) {
  .faq-img {
    margin-top: 50px;
}
}
@media (max-width:425px) {
 
  .faq-section1 {
    padding: 100px 20px;
}
button.accordion-button {
  font-size: 18px;
} 

}