.Aboutcard {
    padding: 30px;
    background-color: #eaefff;
    border-radius: 0.75rem;
    margin: 10px;
    width: 30% ;
}
.Aboutcard:hover {
    transition: .3s linear;
    transform: translateY(-10px);
}

.about-section2 {
    padding: 60px 0px;
}
.about-card-title h3 {
    font-size: 18px;
    line-height: 1rem;
    display: inline-block;
    letter-spacing: .04rem;
    color: #2b5cfd;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
}
.about-card-title h2 {
    font-size: 40px ;
    font-weight: 700;
    color: #0a1425;
    line-height: 3.2rem;
    margin: 0;
}
.about-card-title p {
    font-size: 1.25rem ;
    text-align: center ;
    font-weight: 400;
    line-height: 1.6;
    color: #64728f;
    margin: 18px 245px;
}
.about-section2 .row {
    padding-top: 30px;
    justify-content: center;
}

.abt-card-item p {
    font-size: 16px;
    line-height: 26px;
    color: #71748f;
    font-weight: 400;
    margin-top: 15px;
    text-align: justify;
}

.abt-card-item h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #0a1425;
    margin-bottom: 10px;
}
.abt-card-icon img {
    width: 10%;
    margin-bottom: 12px;
}
.about-card-title {
    text-align: center;
}

/* s */

.abt-sec1-content h3 {
    margin: 0px 0px 20px 0px;
    padding: 5px 10px 5px 10px;
    background-color: #F7F4FF;
    border-radius: 5px;
    color: #5842BC;
    font-size: 14px;
    width: 18% ;
    text-align: center;
}
.abt-sec1-content h3 {
    width: 18% ;
}
.about-section1 {
    padding: 100px 100px 50px 100px ;
    background-image: url("../../../../public/Assests/About/about_s4_lines.png");
    background-position: left;
    background-repeat: no-repeat;
}
.abt-sec1-img img {
    width: 80% ;
}
.abt-sec1-content h2 span {
    background: linear-gradient(90deg, #501E9C 0%,#8169F1 40%,#A44CEE 75%,#FF847F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.abt-sec1-content h2 {
    color: #000000;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.3em;
}
.abt-sec1-content p {
    text-align: justify;
    color: #212529;
    font-size: 16px;
    line-height: 2em;
}
.abt-sec1-img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-section3 .container {
    background-color: transparent;
    background-image: linear-gradient(75deg,#2b5cfd 31%,#1d3faf 76%);
    padding: 60px 0 60px 50px;
    border-radius: 50px;
}
.abt-sec3-content h2 {
    font-size: 3.75rem;
    line-height: 70px;
    color: #fff;
}
.abt-sec3-img img {
    width: 95%;
    margin-right: -1px;
}
.abt-sec3-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.abt-sec3-img {
    text-align: end;
}
a.abt-btn1 {
    background: #fff;
    color: #2b5cfd;
    padding: 11px 63px 11px 63px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
}
a.abt-btn2 {
    color: #ffffff;
    padding: 11px 63px 11px 63px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #fff;
}
.abt-section3-btn {
    display: inline-flex;
    align-items: center;
    gap: 1.7rem;
    margin-top: 30px;
}
.about-section3 {
    padding: 50px 140px;
}
@media only screen and (max-width: 1440px) {
   
    .about-section1 {
        padding: 100px 50px 50px 50px ;
    }
    .abt-sec1-content h3 {
 
        width: 18% ;
    }
}
@media only screen and (max-width: 1024px) {

    .abt-sec1-content h3 {
        width: 25% ;
    }
    .abt-sec1-img img {
        width: 100% ;
    }
    .abt-sec1-content h2 {
        font-size: 38px;
    }
    .about-section1 {
        padding: 100px 30px;
    }
    .abt-sec1-img {
        justify-content: center;
    }

    .about-section3 {
        padding: 50px 50px;
    }
    a.abt-btn1 {
        padding: 11px 12px 11px 12px;
    }
    a.abt-btn2 {
        padding: 11px 12px 11px 12px;
    }
    .abt-sec3-content h2 {
        font-size: 40px;
        line-height: 52px;
    }
    .about-card-title p {
        margin: 18px 0px;

    }
}
@media only screen and (max-width: 768px) {
    .Aboutcard:hover {
        transition: .3s linear;
        transform: translateY(-10px);
        
    }
    .abt-card-icon img {
        height: 2rem;
        width: 2rem;
    }
    .about-section1 .row {
        flex-direction: column-reverse;
    }
    .abt-sec1-content h3 {
        width: 18% ;
    }
    .abt-sec1-content h3 {
        padding: 8px 10px 8px 10px;
    }
    .abt-sec1-img img {
        width: 60%;
        margin-top: 50px;
    }
    .about-section1 {
        padding: 30px 30px 50px 30px ;
    }
    .abt-sec1-img img {
        width: 80% ;
    }
    .about-card-title p {
        font-size: 18px ;
    }

}
@media only screen and (max-width: 520px) {
  
    .abt-sec1-content h3 {
        width: 40% ;
    }

    .abt-sec1-content h2 {
        font-size: 28px;
    }
    .abt-sec1-img img {
        width: 85%;
    }
    .about-card-title h2 {
        font-size: 24px ;
    }
    .about-section2 {
        padding: 60px 15px;
    }
    /* .about-card-title p {
        font-size: 16px !important;
        margin: 0px;
    } */
    .Aboutcard {
        width: 90%;
    }
    
    .about-section1 {
        padding: 100px 20px 50px 20px ;
    }
    .about-card-title p {
        font-size: 16px;
        text-align: justify;
        margin: 5px 0px;
    }
}