section#services {
    background-color: #f6f6f6;
    padding: 100px 0px;
}
.services .service-item {
    padding: 40px;
    background: #fff;
    height: 100%;
    border-radius: 10px;
    transition: .3s ease-in-out;
  }
  
  .services .service-item .icon {
    width: 36%;
    padding: 22px;
    border-radius: 70px;
    /* position: relative; */
    margin-bottom: 50px;
    background-color: #fcb64d;
    text-align: center;
  }
  .icon img {
    width: 75%;
}
.service-item:hover .icon {
  background-color: #fbaf41;
  transition: .5s linear;
}
  .services .service-item .icon i {
    color: #222222;
    font-size: 56px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
  }
  
  /* .services .service-item .icon:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background:#eeeeeed1;
    border-radius: 50px;
    z-index: 1;
    top: -17px;
    right: -41px;
    transition: 0.3s;
  } */
  
  .services .service-item h3 {
    color: var(--color-default);
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 22px;
    position: relative;
    display: inline-block;
    /* border-bottom:c:\Users\Byzero\AppData\Local\Programs\Microsoft VS Code\resources\app\out\vs\code\electron-sandbox\workbench\workbench.html 4px solid #eeeeee; */
    transition: 0.3s;
  }
  .service-item:hover {
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-10px);
}
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .services .service-item .readmore {
    margin-top: 15px;
    display: inline-block;
    color:#d98100;
    text-decoration: none;
  }
  
  /* .services .service-item:hover .icon:before {
    background: #f9b143a8;
  } */
  
  .services .service-item:hover h3 {
    border-color: #f9b143;
  }
  section#services {
    background-color: #f6f6f6;
    padding: 100px 0px;
}
.Features-header {
  text-align: center;
  margin-bottom: 75px;
}

@media only screen and (max-width: 1024px) {

.services .service-item .icon {
  width: 58%;
  padding: 25px;
}
.services .service-item h3 {
  font-size: 18px;
}
}
@media only screen and (max-width: 768px) {
  section#services {
    padding: 30px 0px;
}
.services .service-item {
  padding: 30px;
}
  .services .service-item .icon {
    width: 58%;
    padding: 20px;
    margin-bottom: 15px;
}
.services .service-item h3 {
  font-size: 16px; 
}
.services .service-item p {
  margin-top: 0px;
}
.services .service-item h3 {
  margin: 0 0 10px 0;
}
}


@media only screen and (max-width: 520px) {

  .services .service-item .icon {
    width: 30%;
    padding: 10px;
    margin-bottom: 22px;
}
section#services {
  background-color: #f6f6f6;
  padding: 100px 30px;
}
section#services {
  padding: 70px 0px;
}
.Features-header p {
  text-align: justify;
}
.Features-header h2 {
  font-size: 54px;
}
.services .service-item h3 {
  font-size: 18px;
  margin-bottom: 7px;
}
.services .service-item {
  padding: 25px;
}
.services .service-item p {
  text-align: justify;
}
/* .MuiPaper-root{
  width: 55%;
} */
.MuiButtonBase-root{
  padding-left: 30px;
}
}