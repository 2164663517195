input.form-control::placeholder {
    color: #fff;
    font-weight: 700;
}
.thumbs-wrapper.axis-vertical {
    display: none;
}

.carousel-card {
    display: flex;
    flex-direction: row;
    justify-content: space-around;    
    width: fit-content;
    align-content: center;   
}
.left-h{
    display: flex;
    flex-direction: column;
    padding: 10rem;
    padding-top: 5rem;   
}
.carousel{
    border-radius: 10px;       
}
.text1 {
    display: flex;
    font-family: "Outfit";
    width: 24rem;
    font-size: 3rem;
    color: black;
    line-height: 1;
}
.text2 {
    margin-top: 1rem;
    width: 28rem;
    line-height: 1.2;
    font-family: "Outfit", sans-serif;
    font-weight: 300;
    font-size: 1.6rem;
    color: #b4b4b4;    
}
.readmore-btn{
    margin-top: 1rem;
    display: flex;
    background-color:#fcb040;
    width: fit-content;
    border-radius: 8px;
}
.btn:hover{
    font-family: "Outfit", sans-serif;
    margin-bottom: 2px;
}
.arrow-icon{
    align-self: center;
    position: relative;
    top:2.5px;
    right: 0.6rem;
}

p.carousel-status {
    display: none;
}
.home-section2 {
    padding: 10px 0px;
    height: 45rem;
    background-image: url("../../../../public/Assests/Home_bg.png");
    background-size: 44rem;
    background-position: right;
    background-repeat: no-repeat;
}
.home-section2 .row {   
    display: flex;  
    align-items: center;
}
.home-sec2-content {
    padding: 2rem;
    background-color: #272262;
    color: #fff;
    border-radius: 40px;
}
.home-sec2-content h2 {
    font-size: 3.3rem;
}
.home-sec2-content ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 50px;
    font-size: 25px;
    color: #f5f3f3;
}
.home-sec2-content ul {
    padding: 0px;
    margin-top: 30px;
}
.home-sec2-content ul li:hover {
    color: #ebc400;
    transition: .3s linear;
}
.Features-header h2 {
    color:#262262;
    font-size: 65px;
    font-weight: 500;
}
.Features-header p {
    font-size: 20px;
    margin-top: 16px;
}

/* contact-card */

.contact-card-button form {
    background: #fcb040;
    padding: 16px 39px;
    border-radius: 50px;
}
  
  .contact-card-button .form-control {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    margin-right: 10px;
    border: none !important;
    background: none !important;
  }
  
  .contact-card-button .form-control:hover,
  .contact-card-button .form-control:focus {
    outline: none;
    box-shadow: none;
  }
  
  .contact-card-button .btn-primary {
    background-color: #272262;
    padding: 15px 51px;
    border-radius: 38px;
}
  
.homepage-section4 .row {
    display: flex;
    align-items: center;
}
.homepage-section4 .container {
    padding: 60px;
    background-color: #272262;
    color: #fff;
    border-radius: 20px;
}
.contact-card h2 {
    font-size: 40px;
    font-weight: 700;
}
input.form-control::placeholder {
    font-size: 20px;
}
.homepage-section4 {
    padding: 60px 0px; 
    background-color: #ededed;
    background-image: url("../../../../public/Assests/Home_bg.png");
    background-position:inherit;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 1440px) {
.home-section2 {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.home-sec2-img img {
    width: 90%;
}
.home-sec2-content h2 {
    font-size: 55px;
}
.home-sec2-content {
    padding: 50px 30px;
    
}
}

@media only screen and (max-width: 1024px) {
    .left-h {
        padding: 5rem;
    }
    .right-h {
        padding-top: 2rem;
    }
    .text1{
        font-size: 2rem;
        width: 14rem;
    }
    .text2{
        font-size: 1.3rem;        
    }
    .home-section2 {
    background-size: 35rem;
    }
    .home-sec2-content {
        padding: 25px 36px;
        background-size: 1rem;
    }
    .home-sec2-content h2 {
        font-size: 30px;
    }
    .home-sec2-content ul li {
        font-size: 20px;
    }
    .homepage-section4 .container {
        border-radius: 50px;
    }
    .contact-card h2 {
        font-size: 36px;
    }
    .contact-card-button form {
        padding: 16px 24px;
    }
    .homepage-section4 {
        padding: 60px 0px;
        background-size: inherit;
    }
    .homepage-section4 .container {
        border-radius: 70px;
    }
    .Features-header p {
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .text2 {
        width: 20rem;
        line-height: 1.2;
        font-family: "Outfit", sans-serif;
    }
    .right-h {
        padding: 0.2rem;
        padding-top: 3.5rem;
    }
    .text2{
        font-size: 1.1rem;

    }
    .right-h{
        padding-left: 2rem;
    }
    .home-section2 {
        background-size: 22rem;
        height: 28rem;
    }
    .home-sec2-content{
        position: relative;
        bottom: 20rem;
        left: 23.7rem; 
    }
    .home-sec2-content h2 {
        font-size: 1.5rem;
    }
    .home-sec2-content ul li {
        font-size: 1.2rem;
    }
    .home-sec2-img img {        
        width: 88.5%;
        padding-top: 16rem;
    }
    .Features-header h2 {
        font-size: 60px;
    }
    .Features-header p {
        font-size: 16px;
        position: relative;
        top: 10px;
    }
    .homepage-section4 {
        padding: 65px 50px;
        background-size:contain;   
    }
    .homepage-section4 .container {
        padding: 40px 40px;
        
    }
    .contact-card-button .form-control {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .contact-card p {
        margin: 30px 0px;
    }
    .contact-card-button form {
        padding: 12px 24px;
    }
    .contact-card-button .btn-primary {
        padding: 10px 44px;
    }
}

@media only screen and (max-width: 450px) {
    .carousel-card {
        flex-direction: column;    
    }
    .left-h{
        flex-direction: column;
        padding: 3rem;    
    }
    .right-h {
        padding-top: 0px;
    }
    .carousel {
        padding: 8px;
    }
    .text1{
        font-size: 1.8rem;
        width: fit-content;
    }
    .text2{
        width: fit-content;
        font-size: 1.3rem;
    }
    .home-section2 {
        padding: 15px 15px;
        height: 50rem;
        background-image: none;
    }
    .home-section2 .row {   
        background-image: url("../../../../public/Assests/Home_bg.png");
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 22rem;
        gap: 2.5rem;
        padding-bottom: 2rem;
        position: relative;
        bottom: 8rem;
    }
    .home-sec2-img img {
      width: 98%;
    }
    .home-sec2-content {
        padding-left: 1.5rem;
        position: unset;
        bottom: unset;
        left: unset;
    }   
    .home-sec2-content ul li{
        font-size: 1rem;
        line-height: 2rem;
    }
    .homepage-section4 .row {
        display: flex;
        align-items: center;
    }
    .homepage-section4 .container {
        padding: 35px;
        background-color: #272262;
        color: #fff;
        border-radius: 20px;
    }
    .homepage-section4 {
        padding: 60px 0px; 
        background-color: #ededed;
        background-image: url("../../../../public/Assests/Home_bg.png");
        background-position:inherit;
        background-repeat: no-repeat;
    }
    .contact-card-button form {
        background: #fcb040;
        padding: 10px 20px;
        border-radius: 50px;
    }
    .contact-card-button .form-control {
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        margin-right: 10px;
    }
      .contact-card-button .form-control:hover,
      .contact-card-button .form-control:focus {
        outline: none;
        box-shadow: none;
      }
      .contact-card-button .btn-primary {
        background-color: #272262;
        padding: 5px 40px;
        border-radius: 38px;    
    }
    input.form-control::placeholder {
        font-size: 12px;
    }
}

 /* @media only screen and (width: 2560px) {
    .left-h{
        padding: 12rem;
    }
    .text1 {
        width: 50rem;
        font-size: 6rem;
    }
    .text2 {
        width: 65rem;
        font-size: 3.5rem;
    }
    .readmore-btn{
        font-size: 1.5rem;
    }
    .btn {
        font-size: 2rem;
    }
    .btn:hover{
        font-family: "Outfit", sans-serif;
        margin-bottom: 2px;
        background-color: #faedd9;
    }
    .arrow-icon{
        align-self: center;
        position: relative;
        top:2.5px;
        right: 0.6rem;
    }    
    .home-sec2-img img {
        width:  80%;
        padding-left: 10rem;
        
    }
    .home-section2 {
        padding: 10px 0px;
        height: 55rem;
        background-size: 50rem;
    }
    
    .home-sec2-content {
        padding: 5rem;
        background-color: #272262;
        color: #fff;
        border-radius: 40px;
    }
    .home-sec2-content h2 {
        font-size: 4rem;
    }
    .home-sec2-content ul li {
        list-style-type: none;
        display: flex;
        align-items: center;
        gap: 10px;
        line-height: 5rem;
        font-size: 3rem;
        color: #f5f3f3;
    }
    .home-sec2-content ul li:hover {
        color: #ebc400;
        transition: .3s linear;
    }
    .Features-header h2 {
        font-size: 5.5rem;
    }
    .Features-header p {
        font-size: 2.5rem;
        margin-top: 20px;
    }
} */
